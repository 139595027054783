// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-core-share-share-block-template-js": () => import("/opt/build/repo/src/core/share/ShareBlockTemplate.js" /* webpackChunkName: "component---src-core-share-share-block-template-js" */),
  "component---src-modules-features-features-template-js": () => import("/opt/build/repo/src/modules/features/FeaturesTemplate.js" /* webpackChunkName: "component---src-modules-features-features-template-js" */),
  "component---src-modules-tools-tools-template-js": () => import("/opt/build/repo/src/modules/tools/ToolsTemplate.js" /* webpackChunkName: "component---src-modules-tools-tools-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-awards-js": () => import("/opt/build/repo/src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-conclusion-js": () => import("/opt/build/repo/src/pages/conclusion.js" /* webpackChunkName: "component---src-pages-conclusion-js" */),
  "component---src-pages-demographics-js": () => import("/opt/build/repo/src/pages/demographics.js" /* webpackChunkName: "component---src-pages-demographics-js" */),
  "component---src-pages-environments-js": () => import("/opt/build/repo/src/pages/environments.js" /* webpackChunkName: "component---src-pages-environments-js" */),
  "component---src-pages-features-js": () => import("/opt/build/repo/src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opinions-js": () => import("/opt/build/repo/src/pages/opinions.js" /* webpackChunkName: "component---src-pages-opinions-js" */),
  "component---src-pages-other-tools-js": () => import("/opt/build/repo/src/pages/other-tools.js" /* webpackChunkName: "component---src-pages-other-tools-js" */),
  "component---src-pages-resources-js": () => import("/opt/build/repo/src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-support-js": () => import("/opt/build/repo/src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-technologies-js": () => import("/opt/build/repo/src/pages/technologies.js" /* webpackChunkName: "component---src-pages-technologies-js" */),
  "component---src-pages-tshirt-js": () => import("/opt/build/repo/src/pages/tshirt.js" /* webpackChunkName: "component---src-pages-tshirt-js" */),
  "component---src-pages-units-and-selectors-js": () => import("/opt/build/repo/src/pages/units-and-selectors.js" /* webpackChunkName: "component---src-pages-units-and-selectors-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

